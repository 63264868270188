import { isDefined } from "ceres/resources/js/src/app/helper/utils";
import { navigateTo } from "ceres/resources/js/src/app/services/UrlService";
import { mapState } from "vuex";
import { ButtonSizePropertyMixin } from "ceres/resources/js/src/app/mixins/buttonSizeProperty.mixin";
import axios from "axios";
import countriesList from "./countriesList";

const ApiService = require("ceres/resources/js/src/app/services/ApiService");

export default Vue.component("place-order-axytos", {
  mixins: [ButtonSizePropertyMixin],

  props: {
    template: {
      type: String,
      default: "#vue-place-order",
      name: "PlaceOrderAtriga",
    },
    targetContinue: {
      type: String,
    },
    paddingClasses: {
      type: String,
      default: null,
    },
    paddingInlineStyles: {
      type: String,
      default: null,
    },
    axytosKaufenPaymentForU: {
      type: String,
    },
    axytosKaufenPaymentForS: {
      type: String,
    },
    hi: {
      type: String,
    },
  },

  data() {
    return {
      waiting: false,
      newC: "Axytos",
      axytosResponse: {},
      axytosRequestObject: {},
      ignoreCheck: false,
      blockOrder: false,
      countries: countriesList.countries,
      activeCountriesWithStates: [],
      paymentRules: [],
      hideAxytosBonitCheckbox: true,
      selectedPayMethdSafeOrUnsafe: "",
      onlyAllowSafeMethods: false,
      userRRatedNotAllowAny: false,
      lastCheckCallTimestamp: "",
      checkCallRequired: true,
      modalHTMLdata: "",
      firstTimeLoad: true,
    };
  },
  watch: {
    isBasketLoading(newValue, oldValue) {
      this.globalConsoleLogger(
        `isBasketLoading Updating from ${oldValue} to ${newValue}`
      );
      if (this.firstTimeLoad && newValue === false) {
        this.checkForPreviousCheck();
      }
    },
    methodOfPaymentId(newValue, oldValue) {
      this.globalConsoleLogger(`Updating from ${oldValue} to ${newValue}`);
      this.addBoniCheck(oldValue, newValue);
      this.checkIfModalHasHTML();

      if (
        !$.isEmptyObject(this.axytosResponse) &&
        this.axytosResponse.decision === "S"
      ) {
        this.checkIfOnlySafeTenMinutes(newValue);
      }
      // U -> [S]. Only allow S and not make call

      if (
        !$.isEmptyObject(this.axytosResponse) &&
        this.axytosResponse.decision === "R"
      ) {
        this.checkIfRRatedAllowNoMthd(newValue);
      }
      // U -> [S]. Only allow S and not make call
    },
    hideAxytosBonitCheckbox(newValue, oldValue) {
      this.globalConsoleLogger("Hide flag: " + newValue);
    },
    basketUpdatedAt(newValue, oldValue) {
      if (this.firstTimeLoad) {
        this.checkForPreviousCheck();
        this.firstTimeLoad = false;
      }

      if (!this.userRRatedNotAllowAny) {
        this.globalConsoleLogger(
          `Basket updating at ${oldValue} to ${newValue}. Call to /check required again.`
        );
        this.checkCallRequired = true;
        this.blockOrder = false;
        $(".widget-place-order > div > button").prop("disabled", false);
      } else {
        this.disableButton();
      }
    },
    billingAddressUpdatedAt(newValue, oldValue) {
      if (!this.userRRatedNotAllowAny) {
        this.globalConsoleLogger(
          `billingAddressUpdatedAt ${oldValue} to ${newValue}. Call to /check required again.`
        );
        this.checkCallRequired = true;
        this.blockOrder = false;
        $(".widget-place-order > div > button").prop("disabled", false);
      } else {
        this.disableButton();
      }
    },
    deliveryAddressUpdatedAt(newValue, oldValue) {
      if (!this.userRRatedNotAllowAny) {
        this.globalConsoleLogger(
          `deliveryAddressUpdatedAt ${oldValue} to ${newValue}. Call to /check required again.`
        );
        this.checkCallRequired = true;
        this.blockOrder = false;
        $(".widget-place-order > div > button").prop("disabled", false);
      } else {
        this.disableButton();
      }
    },
  },

  computed: {
    buttonClasses() {
      const classes = [];

      if (isDefined(this.buttonSizeClass)) {
        classes.push(this.buttonSizeClass);
      }

      if (isDefined(this.paddingClasses)) {
        classes.push(this.paddingClasses.split(" "));
      }

      return classes;
    },

    activeNewsletterSubscriptions() {
      const activeNewsletterSubscriptions = [];

      for (const emailFolder in this.newsletterSubscription) {
        const emailFolderValue = this.newsletterSubscription[emailFolder];

        if (emailFolderValue) {
          activeNewsletterSubscriptions.push(emailFolder);
        }
      }

      return activeNewsletterSubscriptions;
    },

    ...mapState({
      checkoutValidation: (state) => state.checkout.validation,
      contactWish: (state) => state.checkout.contactWish,
      customerSign: (state) => state.checkout.customerSign,
      isBasketLoading: (state) => state.basket.isBasketLoading,
      basketItemQuantity: (state) => state.basket.data.itemQuantity,
      isBasketInitiallyLoaded: (state) => state.basket.isBasketInitiallyLoaded,
      shippingPrivacyHintAccepted: (state) =>
        state.checkout.shippingPrivacyHintAccepted,
      newsletterSubscription: (state) => state.checkout.newsletterSubscription,
      billingAddress: (state) => state.address,
      fullState: (state) => state,
      allBillingTypes: (state) => state.checkout.payment.methodOfPaymentList,
      methodOfPaymentId: (state) => state.checkout.payment.methodOfPaymentId,
      basketUpdatedAt: (state) => state.basket.data.basketAmount,
      billingAddressUpdatedAt: (state) => state.address.billingAddress,
      deliveryAddressUpdatedAt: (state) => state.address.deliveryAddress || "",
    }),
  },

  mounted() {
    this.globalConsoleLogger("Kaufen mit Axytos");
    this.globalConsoleLogger("App.config");
    this.globalConsoleLogger(App.config);

    const urlLoadUnauth = "/webWikinger/rule/loadUnauth";

    // get all rules
    ApiService.get(urlLoadUnauth)
      .done((response) => {
        this.globalConsoleLogger(response);
        this.paymentRules = response;

        this.addBoniCheck(0, this.methodOfPaymentId);
      })
      .fail((response) => {
        this.globalConsoleLogger(response);
      });

    // get active countries
    ApiService.get("/webWikinger/getActiveCountriesList")
      .done((response) => {
        this.globalConsoleLogger(response);
        this.activeCountriesWithStates = response.activeCountries;
      })
      .fail((response) => {
        this.globalConsoleLogger(response);
      });
    // get HTML for Axytps modal
    ApiService.get("/webWikinger/getStaticCreditCheckAgreement")
      .done((response) => {
        this.globalConsoleLogger(response);
        // if (document.getElementById("axytosCheckboxModalDesc")) {
        //     document.getElementById(
        //         "axytosCheckboxModalDesc"
        //     ).innerHTML = response.htmlContent;
        // }
        try {
          this.modalHTMLdata = "<p></p>";
          JSON.parse(response.htmlContent);
        } catch (e) {
          this.modalHTMLdata = response.htmlContent;
          this.checkIfModalHasHTML();
        }
        // if (JSON.parse(response.htmlContent).error === undefined) {
        //   this.modalHTMLdata = response.htmlContent;
        //   this.checkIfModalHasHTML();
        // }
      })
      .fail((response) => {
        this.globalConsoleLogger(response);
      });
  },
  methods: {
    checkForPreviousCheck() {
      // check if localstorage has has /check details
      let lastAxytosDecision = localStorage.getItem("lastAxytosDecision");

      if (lastAxytosDecision !== null) {
        lastAxytosDecision = JSON.parse(lastAxytosDecision);
        const lastTimeStamp = new Date(
          lastAxytosDecision.transactionMetadata.transactionTimestamp
        ).getTime();

        this.globalConsoleLogger(
          "Check in localstorage last decision " +
            Date.now() +
            " " +
            lastTimeStamp +
            " " +
            (Date.now() - lastTimeStamp) / 60000
        );
        this.globalConsoleLogger(lastAxytosDecision);

        this.globalConsoleLogger(Date.now() - lastTimeStamp < 600000);

        if (Date.now() - lastTimeStamp < 600000) {
          this.checkCallRequired = false;
          this.axytosResponse = lastAxytosDecision;
          if (this.axytosResponse.decision === "S") {
            this.onlyAllowSafeMethods = true;
            window.CeresNotification.error(
              "Die von Ihnen gewählte Zahlart kann leider derzeit nicht angeboten werden. Bitte nutzen Sie eine andere Zahlart."
            );

            this.waiting = false;
            this.axytosRequestObject = "";

            this.addBoniCheck(0, this.methodOfPaymentId);

            setTimeout(() => {
              this.checkIfOnlySafeTenMinutes(this.methodOfPaymentId);
            }, 700);
          } else if (this.axytosResponse.decision === "R") {
            window.CeresNotification.error(
              "Die von Ihnen gewählte Zahlart kann leider derzeit nicht angeboten werden. Bitte nutzen Sie eine andere Zahlart."
            );
            this.userRRatedNotAllowAny = true;
            this.waiting = false;

            this.addBoniCheck(0, this.methodOfPaymentId);

            // disable button for the first time
            this.checkIfRRatedAllowNoMthd();
            setTimeout(
              () => {
                this.checkCallMakeTrue();
              },
              localStorage.getItem("wwDebug")
                ? 30000
                : Date.now() - lastTimeStamp
            );
          }
          // setTimeout(
          //     () => {
          //         this.checkCallMakeTrue();
          //     },
          //     localStorage.getItem("wwDebug")
          //         ? 30000
          //         : Date.now() - JSON.parse(lastAxytosDecision).timestamp
          // );
        } else {
          localStorage.removeItem("lastAxytosDecision");
          localStorage.removeItem("AxytosTransactionID");
          localStorage.removeItem("AxytosConfirmObject");
        }
      }
    },
    addBoniCheck(oldValue, newValue) {
      this.globalConsoleLogger("addBoniCheck");

      const modalCode = `<div class="modal fade" id="axytosCheckboxModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Informationen zur Bonitätsprüfung</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" id="axytosCheckboxModalDesc">
                  &nbsp;
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>`;
      const xxxx = `<div id=\"axytos-accept34-container\" class=\"form-check\"><input type=\"checkbox\" value=\"\" id=\"axytos-accept34\"  class=\"form-check-input\" data-enpassusermodified=\"yes\"> <span for=\"axytos-accept34\" class=\"form-check-label\"><label for="axytos-accept34">Ich willige darin ein, dass eine <a data-toggle="modal" data-target="#axytosCheckboxModal" style=\" color: #008ebd; cursor: pointer;\" class="text-appearance">Bonitätsprüfung</a> zu meiner Person durchgeführt werden kann. Meine Einwilligung kann ich jederzeit für die Zukunft widerrufen. Mir ist bekannt, dass ein Widerruf keine Auswirkungen auf die Rechtmäßigkeit der bis zum Widerruf erfolgten Verarbeitung hat und mir im Falle eines Widerrufs nicht mehr alle Zahlarten angeboten werden können. *</label></span></div>`;
      $("body").append(modalCode);

      // $(".widget-gtc-check").append(xxxx);

      for (let i = 0; i < this.paymentRules.length; i++) {
        // check if in rules
        if (
          (this.paymentRules[i].axytosMode === "Safe" ||
            this.paymentRules[i].axytosMode === "Unsafe") &&
          parseInt(this.paymentRules[i].paymentMethodId) === newValue
        ) {
          this.globalConsoleLogger(
            this.paymentRules[i].paymentMethodId,
            newValue,
            (this.paymentRules[i].axytosMode === "Safe" ||
              this.paymentRules[i].axytosMode === "Unsafe") &&
              parseInt(this.paymentRules[i].paymentMethodId) === newValue
          );

          this.selectedPayMethdSafeOrUnsafe = this.paymentRules[i].axytosMode;

          // hasValue = true; then show checkbox if not exists
          this.hideAxytosBonitCheckbox = false;
          if (document.getElementById("axytos-accept34") === null) {
            $(".widget-gtc-check").append(xxxx);
            $("#axytos-accept34-container").css("display", "block");
            document.getElementById("axytos-accept34").checked = false;
          } else {
            $("#axytos-accept34-container").css("display", "block");
            document.getElementById("axytos-accept34").checked = false;
          }

          // more than ten minutes
          if (
            this.selectedPayMethdSafeOrUnsafe !== "Unsafe" &&
            this.onlyAllowSafeMethods === true
          ) {
            this.blockOrder = false;
            $(".widget-place-order > div > button").prop("disabled", false);
          }

          break;
        } else {
          //
          this.selectedPayMethdSafeOrUnsafe = "Ignore";
          this.onlyAllowSafeMethods = false;
          this.blockOrder = false;

          $(".widget-place-order > div > button").prop("disabled", false);

          // check if exists, then hide. Else do nothing.

          if (document.getElementById("axytos-accept34") !== null) {
            this.hideAxytosBonitCheckbox = true;
            document.getElementById("axytos-accept34").checked = true;
            $("#axytos-accept34-container").css("display", "none");
          }
        }
      }
    },

    checkIfOnlySafeTenMinutes(newValue) {
      this.globalConsoleLogger("checkIfOnlySafeTenMinutes");

      for (let i = 0; i < this.paymentRules.length; i++) {
        // check if in rules
        // if U -> [S]
        // then disbale buy button for unsafe payment methods

        if (
          this.paymentRules[i].axytosMode === "Unsafe" &&
          parseInt(this.paymentRules[i].paymentMethodId) === newValue
        ) {
          this.globalConsoleLogger(
            this.paymentRules[i].paymentMethodId,
            newValue,
            this.paymentRules[i].axytosMode === "Unsafe" &&
              parseInt(this.paymentRules[i].paymentMethodId) === newValue
          );

          this.globalConsoleLogger(
            "disbaling the button again. because unsafe"
          );
          this.blockOrder = true;
          setTimeout(() => {
            $(".widget-place-order > div > button").prop("disabled", true);
          }, 700);
          break;
        }
      }
    },

    checkIfRRatedAllowNoMthd(newValue) {
      // Only allow Ignore methods in case of R.
      this.globalConsoleLogger("checkIfRRatedAllowNoMthd");

      this.blockOrder = true;
      setTimeout(() => {
        $(".widget-place-order > div > button").prop("disabled", true);
      }, 700);
      // for (let i = 0; i < this.paymentRules.length; i++)
      // {
      //     // check if in rules
      //     // if U -> [S]
      //     // then disbale buy button for unsafe payment methods

      //     if (
      //         this.paymentRules[i].axytosMode !== "Ignore" &&
      //         parseInt(this.paymentRules[i].paymentMethodId) === newValue
      //     )
      //     {
      //         this.globalConsoleLogger(
      //             this.paymentRules[i].paymentMethodId,
      //             newValue,
      //             this.paymentRules[i].axytosMode === "Unsafe" &&
      //                 parseInt(this.paymentRules[i].paymentMethodId) ===
      //                     newValue
      //         );

      //         this.globalConsoleLogger(
      //             "disbaling the button again. because " +
      //                 this.paymentRules[i].axytosMode +
      //                 ". Only Ignore allowed."
      //         );
      //         setTimeout(() =>
      //         {
      //             $(".widget-place-order > div > button").prop(
      //                 "disabled",
      //                 true
      //             );
      //         }, 700);
      //         break;
      //     }
      // }
    },
    placeOrder() {
      if (!this.blockOrder) {
        this.placeOrderButFirstCheck();
      }
    },
    placeOrderPost() {
      this.waiting = true;

      const url = "/rest/io/order/additional_information";
      const params = {
        billingAddress: this.billingAddress,
        orderCustomerSign: this.customerSign,
        shippingPrivacyHintAccepted: this.shippingPrivacyHintAccepted,
        newsletterSubscriptions: this.activeNewsletterSubscriptions,
      };
      const options = { supressNotifications: true };

      ApiService.post(url, params, options).always(() => {
        this.preparePayment();
      });
    },
    placeOrderButFirstCheck() {
      if (
        document.getElementsByClassName("widget-gtc-check")[0].childNodes[0]
          .childNodes[0].checked === false ||
        (document.getElementById("axytos-accept34") &&
          document.getElementById("axytos-accept34").checked === false)
      ) {
        if (
          document.getElementsByClassName("widget-gtc-check")[0].childNodes[0]
            .childNodes[0].checked === false
        ) {
          window.CeresNotification.error(
            "Bitte akzeptieren Sie die AGB, Widerrufsrecht und Datenschutzerklärung."
          );
          document
            .getElementsByClassName("widget-gtc-check")[0]
            .childNodes[0].classList.add("error");
        }
        if (document.getElementById("axytos-accept34")) {
          if (document.getElementById("axytos-accept34").checked === false) {
            window.CeresNotification.error(
              "Bitte willigen Sie in die Bonitätsprüfung ein."
            );
            document
              .getElementById("axytos-accept34-container")
              .classList.add("error");
            // document
            //     .getElementById("axytos-accept34-container")
            //     .setAttribute(
            //         "style",
            //         "border:dashed; border-color: #dc3545; padding: 0.5em 1.75rem 0em"
            //     );
          }
        }
      } else {
        this.waiting = true;
        document
          .getElementsByClassName("widget-gtc-check")[0]
          .childNodes[0].classList.remove("error");
        if (document.getElementById("axytos-accept34")) {
          document
            .getElementById("axytos-accept34-container")
            .classList.remove("error");
          // document
          //     .getElementById("axytos-accept34-container")
          //     .removeAttribute(
          //         "style",
          //         "border:dashed; border-color: #dc3545; padding: 0.5em 1.75rem 0em"
          //     );
        }

        // if payment method  type === IGNORE
        if (this.hideAxytosBonitCheckbox) {
          this.ignoreCheck = true;
          this.placeOrderPost();
          return;
        }

        // If call NOT already made and after 10 mins or basket updated
        if (this.checkCallRequired) {
          const url = "/webWikinger/creditCheckerBody/?";

          const newParams = this.axytosRequestObjectCreator();

          this.axytosRequestObject = newParams;

          var config = {
            method: "post",
            url: url,
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              bodyData: newParams,
              selectedPlentyPaymentId:
                this.fullState.checkout.payment.methodOfPaymentId,
            }),
          };
          this.globalConsoleLogger("Data ", { bodyData: newParams });
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));

              this.checkCallRequired = false;

              // set in locastorage with timestamp
              localStorage.setItem(
                "lastAxytosDecision",
                JSON.stringify(response.data.axy)
              );

              setTimeout(
                () => {
                  this.globalConsoleLogger("reset the checkCallRequired B ");
                  this.checkCallMakeTrue();
                },
                localStorage.getItem("wwDebug") ? 30000 : 600000
              );

              // TODO: research - if response should be false, it returns an object
              if (response.data.axy === null) {
                window.CeresNotification.error(
                  "Die von Ihnen gewählte Zahlart kann leider derzeit nicht angeboten werden. Bitte nutzen Sie eine andere Zahlart."
                );
                this.waiting = false;
              } else {
                this.globalConsoleLogger(response);

                this.lastCheckCallTimestamp = Date.now();

                this.axytosResponse = response.data.axy;

                // -1
                if (response.data.axytosMode === "Ignore") {
                  this.ignoreCheck = true;
                  this.placeOrderPost();
                }

                // 2
                if (response.data.axy.decision === "R") {
                  window.CeresNotification.error(
                    "Die von Ihnen gewählte Zahlart kann leider derzeit nicht angeboten werden. Bitte nutzen Sie eine andere Zahlart."
                  );
                  this.userRRatedNotAllowAny = true;
                  this.waiting = false;

                  // disable button for ten minutes
                  this.blockOrder = true;
                  setTimeout(() => {
                    $(".widget-place-order > div > button").prop(
                      "disabled",
                      true
                    );
                  }, 700);
                  setTimeout(
                    () => {
                      this.globalConsoleLogger(
                        "//disable button for ten minutes "
                      );
                      this.blockOrder = false;

                      $(".widget-place-order > div > button").prop(
                        "disabled",
                        false
                      );
                    },
                    localStorage.getItem("wwDebug") ? 30000 : 600000
                  );
                } else if (
                  // 1
                  response.data.axy.approvedPaymentTypeSecurities.length ===
                    1 &&
                  response.data.axy.approvedPaymentTypeSecurities[0] === "S" &&
                  response.data.axytosMode === "Unsafe"
                ) {
                  this.blockOrder = true;
                  $(".widget-place-order > div > button").prop(
                    "disabled",
                    true
                  );
                  this.onlyAllowSafeMethods = true;
                  window.CeresNotification.error(
                    "Die von Ihnen gewählte Zahlart kann leider derzeit nicht angeboten werden. Bitte nutzen Sie eine andere Zahlart."
                  );

                  this.waiting = false;
                  this.axytosRequestObject = "";

                  this.blockOrder = true;
                  setTimeout(() => {
                    $(".widget-place-order > div > button").prop(
                      "disabled",
                      true
                    );
                  }, 700);
                } else {
                  // 0
                  this.placeOrderPost();
                }
              }
            })
            .catch((error) => {
              console.log(error);
              this.globalConsoleLogger(error);
            });

          // ApiService.get(url, newParams)
          //   .done((response) => {
          //     this.checkCallRequired = false;
          //     // ten minutes timer
          //     // setTimeout(
          //     //     () => (this.checkCallRequired = true),
          //     //     60000
          //     // );

          //     // setTimeout(this.checkCallMakeTrue(), 10000);
          //     // setTimeout(function()
          //     // {
          //     //     this.globalConsoleLogger("reset the checkCallRequired ");
          //     //     // self.checkCallMakeTrue();
          //     //     this.checkCallMakeTrue();
          //     // }, 13000);

          //     // set in locastorage with timestamp
          //     localStorage.setItem(
          //       "lastAxytosDecision",
          //       JSON.stringify(response.axy)
          //     );

          //     setTimeout(
          //       () => {
          //         this.globalConsoleLogger("reset the checkCallRequired B ");
          //         this.checkCallMakeTrue();
          //       },
          //       localStorage.getItem("wwDebug") ? 30000 : 600000
          //     );

          //     // TODO: research - if response should be false, it returns an object
          //     if (response.axy === null) {
          //       window.CeresNotification.error(
          //         "Die von Ihnen gewählte Zahlart kann leider derzeit nicht angeboten werden. Bitte nutzen Sie eine andere Zahlart."
          //       );
          //       this.waiting = false;
          //     } else {
          //       this.globalConsoleLogger(response);

          //       this.lastCheckCallTimestamp = Date.now();

          //       this.axytosResponse = response.axy;

          //       // DELETE THIS
          //       // response.axy.approvedPaymentTypeSecurities = [
          //       //     "S"
          //       // ];

          //       // -1
          //       if (response.axytosMode === "Ignore") {
          //         this.ignoreCheck = true;
          //         this.placeOrderPost();
          //       }

          //       // 2
          //       if (response.axy.decision === "R") {
          //         window.CeresNotification.error(
          //           "Die von Ihnen gewählte Zahlart kann leider derzeit nicht angeboten werden. Bitte nutzen Sie eine andere Zahlart."
          //         );
          //         this.userRRatedNotAllowAny = true;
          //         this.waiting = false;

          //         // disable button for ten minutes
          //         setTimeout(() => {
          //           $(".widget-place-order > div > button").prop(
          //             "disabled",
          //             true
          //           );
          //         }, 700);
          //         setTimeout(
          //           () => {
          //             this.globalConsoleLogger(
          //               "//disable button for ten minutes "
          //             );
          //             $(".widget-place-order > div > button").prop(
          //               "disabled",
          //               false
          //             );
          //           },
          //           localStorage.getItem("wwDebug") ? 30000 : 600000
          //         );
          //       } else if (
          //         // 1
          //         response.axy.approvedPaymentTypeSecurities.length === 1 &&
          //         response.axy.approvedPaymentTypeSecurities[0] === "S" &&
          //         response.axytosMode === "Unsafe"
          //       ) {
          //         $(".widget-place-order > div > button").prop(
          //           "disabled",
          //           true
          //         );
          //         this.onlyAllowSafeMethods = true;
          //         window.CeresNotification.error(
          //           "Die von Ihnen gewählte Zahlart kann leider derzeit nicht angeboten werden. Bitte nutzen Sie eine andere Zahlart."
          //         );

          //         this.waiting = false;
          //         this.axytosRequestObject = "";

          //         setTimeout(() => {
          //           $(".widget-place-order > div > button").prop(
          //             "disabled",
          //             true
          //           );
          //         }, 700);
          //       }
          //       // Only allow S payment methods
          //       // else if (
          //       //     response.axy.approvedPaymentTypeSecurities
          //       //         .length === 1 &&
          //       //     response.axy
          //       //         .approvedPaymentTypeSecurities[0] ===
          //       //         "S"
          //       // ) {
          //       //     this.globalConsoleLogger("Only allow Safe methods");
          //       //     if (
          //       //         this.selectedPayMethdSafeOrUnsafe ===
          //       //         "Unsafe"
          //       //     ) {
          //       //         alert(
          //       //             "Wählen Sie eine andere Zahlungsmethode"
          //       //         );
          //       //         $(
          //       //             ".widget-place-order > div > button"
          //       //         ).prop("disabled", true);
          //       //     }

          //       // Wählen Sie eine andere Zahlungsmethode
          //       // }
          //       else {
          //         // 0
          //         this.placeOrderPost();
          //       }
          //     }
          //   })
          //   .fail((response) => {
          //     this.globalConsoleLogger(response);
          //   });
        } else {
          this.placeOrderPost();
        }
      }
    },

    preparePayment() {
      this.waiting = true;

      if (this.validateCheckout() && this.basketItemQuantity > 0) {
        ApiService.post("/rest/io/checkout/payment")
          .done((response) => {
            this.afterPreparePayment(response);
          })
          .fail((error) => {
            this.waiting = false;
          });
      } else {
        this.waiting = false;
      }
    },

    validateCheckout() {
      let isValid = true;

      for (const index in this.checkoutValidation) {
        if (this.checkoutValidation[index].validate) {
          this.checkoutValidation[index].validate();

          if (this.checkoutValidation[index].showError) {
            isValid = !this.checkoutValidation[index].showError;
          }
        }
      }

      return isValid;
    },

    afterPreparePayment(response) {
      const paymentType = response.type || "errorCode";
      const paymentValue = response.value || "";

      switch (paymentType) {
        case "continue":
          var target = this.targetContinue || "/place-order";

          if (target) {
            if (
              (target === "/place-order" || target === "/place-order/") &&
              this.ignoreCheck === false
            ) {
              this.axytosOrderConfirmer();
            } else {
              navigateTo(target);
            }
          }
          break;
        case "redirectUrl":
          // redirect to given payment provider
          window.location.assign(paymentValue);
          break;
        case "externalContentUrl":
          // show external content in iframe
          this.showModal(paymentValue, true);
          break;
        case "htmlContent":
          this.showModal(paymentValue, false);
          break;

        case "errorCode":
          window.CeresNotification.error(paymentValue);
          this.waiting = false;
          break;
        default:
          window.CeresNotification.error(
            "Unknown response from payment provider: " + paymentType
          );
          this.waiting = false;
          break;
      }
    },

    showModal(content, isExternalContent) {
      if (isExternalContent) {
        this.$emit("payment-response", '<iframe src="' + content + '">');
      } else {
        this.$emit("payment-response", content);
      }
    },

    axytosRequestObjectCreator() {
      const newParams = {
        requestMode: "SingleStep".trim(),
        customReference: "".trim(),
        proofOfInterest: "AAE".trim(),
        personalData: {
          externalCustomerId: String(
            this.fullState.user.userData !== null
              ? this.fullState.user.userData.id
              : this.fullState.user.userData?.email?.trim() ||
                  this.fullState.address.billingAddress.options[0].value?.trim()
          ),
          language: "DE",
          dateOfBirth:
            this.fullState.user.userData?.birthdayAt ||
            (this.fullState.address.billingAddress.options.filter(function (
              itm
            ) {
              return itm.typeId === 9;
            })[0] &&
              this.fullState.address.billingAddress.options.filter(function (
                itm
              ) {
                return itm.typeId === 9;
              })[0].value + "T00:00:00.000Z") ||
            null,
          gender:
            this.fullState.address.billingAddress.gender === "company"
              ? ""
              : this.fullState.address.billingAddress.gender === "male"
              ? "M".trim()
              : this.fullState.address.billingAddress.gender === "female"
              ? "F".trim()
              : this.fullState.address.billingAddress.gender === "diverse"
              ? "D"
              : "",
          email:
            this.fullState.user.userData?.email?.trim() ||
            this.fullState.address.billingAddress.options[0].value?.trim() ||
            "".trim(),
          fixNetPhoneNumber:
            this.fullState.user.userData?.privatePhone?.trim() ||
            this.fullState.address.billingAddress.options
              .filter(function (itm) {
                return itm.typeId === 4;
              })[0]
              ?.value.trim() ||
            "".trim(),
          mobilePhoneNumber:
            this.fullState.user.userData?.privateMobile?.trim() || "".trim(),
        },
        invoiceAddress: {
          company:
            this.fullState.address.billingAddress.gender === "company"
              ? this.fullState.address.billingAddress.name1?.trim()
              : "".trim(),
          firstname:
            this.fullState.address.billingAddress.gender === "company" ||
            this.fullState.address.billingAddress.gender === "diverse"
              ? this.fullState.address.billingAddress.name2?.trim()
              : this.fullState.address.billingAddress.name2?.trim(),
          lastname:
            this.fullState.address.billingAddress.gender === "company" ||
            this.fullState.address.billingAddress.gender === "diverse"
              ? this.fullState.address.billingAddress.name3?.trim()
              : this.fullState.address.billingAddress.name3?.trim(),
          country: this.countries
            .find(
              (countryObject) =>
                countryObject.plentymarketsID ===
                this.fullState.address.billingAddress.countryId
            )
            .ISOCode?.trim(),
          region: this.fullState.address.billingAddress.stateId
            ? this.activeCountriesWithStates
                .map((country) => {
                  return country.states.filter((itm) => {
                    return (
                      itm.id === this.fullState.address.billingAddress.stateId
                    );
                  });
                })[0][0]
                .name?.trim()
            : "",
          zipCode: this.fullState.address.billingAddress?.postalCode?.trim(),
          city: this.fullState.address.billingAddress?.town?.trim(),
          addressLine1: this.fullState.address.billingAddress.address1?.trim(),
          addressLine2: this.fullState.address.billingAddress.address2?.trim(),
          addressLine3:
            this.fullState.address.billingAddress.address3?.trim() || "".trim(),
          addressLine4:
            this.fullState.address.billingAddress.address4?.trim() || "".trim(),
        },

        basket: {
          grossTotal:
            this.fullState.basket.data.openAmount ||
            this.fullState.basket.data.basketAmount,
          currency: this.fullState.basket.data.currency?.trim(),
          netTotal: this.fullState.basket.data.basketAmountNet,
          positions: this.fullState.basket.items
            .map((item) => {
              return {
                productId: item.itemId,
                grossPositionTotal: String(item.price * item.quantity),
                netPositionTotal: String(
                  item.variation.data.prices.default.data.basePriceNet *
                    item.quantity
                ),
                quantity: item.quantity,
                productCategory: item.variation.data.texts.urlPath
                  .split("/")
                  .slice(0, -1)
                  .join("/")
                  .trim(),
                taxPercent: item.variation.data.prices.default.vat.value,
                productName: item.variation.data.texts.name1?.trim(),
                // netPricePerUnit: String(
                //     item.variation.data.prices.rrp.data
                //         .unitPriceNet
                // ),
                // grossPricePerUnit: String(
                //     item.variation.data.prices.rrp.data
                //         .unitPrice
                // )
              };
            })
            .concat({
              productId: 0,
              grossPositionTotal: this.fullState.basket.data.shippingAmount,
              netPositionTotal: this.fullState.basket.data.shippingAmountNet,
              quantity: 1,
              productCategory: "Shipping".trim(),
              taxPercent: this.fullState.basket.data.totalVats[0].vatValue,
              productName:
                this.fullState.checkout.shipping.selectedShippingProfile.parcelServiceName?.trim() +
                " - " +
                (this.fullState.checkout.shipping.selectedShippingProfile
                  .parcelServicePresetName !== null
                  ? this.fullState.checkout.shipping.selectedShippingProfile.parcelServicePresetName?.trim()
                  : ""),
            }),
        },
        selectedPaymentType: this.fullState.checkout.payment.methodOfPaymentId,
        paymentTypeSecurity: this.paymentRules
          .filter((paymenttype) => {
            return (
              parseInt(paymenttype.paymentMethodId) ===
              this.fullState.checkout.payment.methodOfPaymentId
            );
          })[0]
          ?.statusId.substring(1, 0),
      };

      // add company details if company
      if (
        this.fullState.address.billingAddress.gender === "company" &&
        this.fullState.address.billingAddress.name1 !== ""
      ) {
        newParams.personalData.company = {
          name: this.fullState.address.billingAddress.name1?.trim(),
          number: "",
          legalForm: "",
          uid: "",
          foundationDate: "",
        };
      }

      // if guest buy
      if (this.fullState.address.deliveryAddress.id === -99) {
        newParams.deliveryAddress = newParams.invoiceAddress;
      } else {
        newParams.deliveryAddress = {
          country: this.countries
            .find(
              (countryObject) =>
                countryObject.plentymarketsID ===
                this.fullState.address.deliveryAddress.countryId
            )
            .ISOCode?.trim(),
          region: this.fullState.address.deliveryAddress.stateId
            ? this.activeCountriesWithStates
                .map((country) => {
                  return country.states.filter((itm) => {
                    return (
                      itm.id === this.fullState.address.deliveryAddress.stateId
                    );
                  });
                })[0][0]
                .name?.trim()
            : "",
          zipCode: this.fullState.address.deliveryAddress.postalCode?.trim(),
          city: this.fullState.address.deliveryAddress.town?.trim(),
          addressLine1: this.fullState.address.deliveryAddress.address1?.trim(),
          addressLine2: this.fullState.address.deliveryAddress.address2?.trim(),
          addressLine3:
            this.fullState.address.deliveryAddress.address3?.trim() || "",
          addressLine4:
            this.fullState.address.deliveryAddress.address4?.trim() || "",
          company:
            this.fullState.address.deliveryAddress.gender === "company"
              ? this.fullState.address.deliveryAddress.name1?.trim()
              : "",
          firstname:
            this.fullState.address.deliveryAddress.gender === "company" ||
            this.fullState.address.deliveryAddress.gender === "diverse"
              ? this.fullState.address.deliveryAddress.name2?.trim()
              : this.fullState.address.deliveryAddress.name2?.trim(),

          lastname:
            this.fullState.address.deliveryAddress.gender === "company" ||
            this.fullState.address.deliveryAddress.gender === "diverse"
              ? this.fullState.address.deliveryAddress.name3?.trim()
              : this.fullState.address.deliveryAddress.name3?.trim(),
        };
      }

      // check for coupon
      if (this.fullState.basket.data.couponCode !== "") {
        newParams.basket.positions.push({
          productId: this.fullState.basket.data.couponCode?.trim(),
          grossPositionTotal: this.fullState.basket.data.couponDiscount,
          netPositionTotal: this.fullState.basket.data.couponDiscount,
          quantity: 1,
          productCategory: "Voucher".trim(),
          taxPercent: 0,
          productName: "Gutschein".trim(),
        });
      }

      if (localStorage.getItem("emptyCompanyName")) {
        newParams.personalData.company.name = "";
      }

      this.globalConsoleLogger("reqObj: ");
      this.globalConsoleLogger(newParams);
      return newParams;
    },
    checkCallMakeTrue() {
      this.globalConsoleLogger("inside the checkCallMakeTrue function ");
      this.checkCallRequired = true;

      this.blockOrder = false;
      $(".widget-place-order > div > button").prop("disabled", false);
    },
    disableButton() {
      this.globalConsoleLogger("Disabling button and blocking checkout");
      this.blockOrder = true;
      setTimeout(() => {
        $(".widget-place-order > div > button").prop("disabled", true);
      }, 700);
    },

    axytosOrderConfirmer() {
      // const url = "/webWikinger/axtyos-confirm";

      const confirmObject = this.axytosRequestObjectCreator();
      // this.axytosRequestObject;

      confirmObject["paymentControlResponse"] = this.axytosResponse;
      delete confirmObject["proofOfInterest"];
      delete confirmObject["requestMode"];
      delete confirmObject["selectedPaymentType"];

      this.globalConsoleLogger(confirmObject);
      const confirmObjectString = { body: JSON.stringify(confirmObject) };

      localStorage.setItem(
        "AxytosTransactionID",
        this.axytosResponse.transactionMetadata.transactionId
      );

      localStorage.setItem(
        "AxytosConfirmObject",
        JSON.stringify(confirmObjectString)
      );

      const ifTrue = localStorage.getItem("wwDebug");

      if (!ifTrue) {
        if (!this.blockOrder) {
          navigateTo("/place-order");
        }
      }
    },
    checkIfModalHasHTML() {
      if (
        document.getElementById("axytosCheckboxModalDesc")?.innerHTML.trim() ===
        "&nbsp;"
      ) {
        document.getElementById("axytosCheckboxModalDesc").innerHTML =
          this.modalHTMLdata;
      }
    },
    globalConsoleLogger(value) {
      const ifTrue = localStorage.getItem("wwDebug");

      if (ifTrue) {
        console.log(value);
      }
    },
  },
});
