const countries = [
    {
        Country: "Germany",
        ISOCode: "DE",
        plentymarketsID: 1
    },
    {
        Country: "Austria",
        ISOCode: "AT",
        plentymarketsID: 2
    },
    {
        Country: "Belgium",
        ISOCode: "BE",
        plentymarketsID: 3
    },
    {
        Country: "Switzerland",
        ISOCode: "CH",
        plentymarketsID: 4
    },
    {
        Country: "Cyprus",
        ISOCode: "CY",
        plentymarketsID: 5
    },
    {
        Country: "Czech Republic",
        ISOCode: "CZ",
        plentymarketsID: 6
    },
    {
        Country: "Denmark",
        ISOCode: "DK",
        plentymarketsID: 7
    },
    {
        Country: "Spain",
        ISOCode: "ES",
        plentymarketsID: 8
    },
    {
        Country: "Estonia",
        ISOCode: "EE",
        plentymarketsID: 9
    },
    {
        Country: "France",
        ISOCode: "FR",
        plentymarketsID: 10
    },
    {
        Country: "Finland",
        ISOCode: "FI",
        plentymarketsID: 11
    },
    {
        Country: "United Kingdom",
        ISOCode: "GB",
        plentymarketsID: 12
    },
    {
        Country: "Greece",
        ISOCode: "GR",
        plentymarketsID: 13
    },
    {
        Country: "Hungary",
        ISOCode: "HU",
        plentymarketsID: 14
    },
    {
        Country: "Italy",
        ISOCode: "IT",
        plentymarketsID: 15
    },
    {
        Country: "Ireland",
        ISOCode: "IE",
        plentymarketsID: 16
    },
    {
        Country: "Luxembourg",
        ISOCode: "LU",
        plentymarketsID: 17
    },
    {
        Country: "Latvia",
        ISOCode: "LV",
        plentymarketsID: 18
    },
    {
        Country: "Malta",
        ISOCode: "MT",
        plentymarketsID: 19
    },
    {
        Country: "Norway",
        ISOCode: "NO",
        plentymarketsID: 20
    },
    {
        Country: "Netherlands",
        ISOCode: "NL",
        plentymarketsID: 21
    },
    {
        Country: "Portugal",
        ISOCode: "PT",
        plentymarketsID: 22
    },
    {
        Country: "Poland",
        ISOCode: "PL",
        plentymarketsID: 23
    },
    {
        Country: "Sweden",
        ISOCode: "SE",
        plentymarketsID: 24
    },
    {
        Country: "Singapore",
        ISOCode: "SG",
        plentymarketsID: 25
    },
    {
        Country: "Slovakia",
        ISOCode: "SK",
        plentymarketsID: 26
    },
    {
        Country: "Slovenia",
        ISOCode: "SI",
        plentymarketsID: 27
    },
    {
        Country: "USA",
        ISOCode: "US",
        plentymarketsID: 28
    },
    {
        Country: "Australia",
        ISOCode: "AU",
        plentymarketsID: 29
    },
    {
        Country: "Canada",
        ISOCode: "CA",
        plentymarketsID: 30
    },
    {
        Country: "China",
        ISOCode: "CN",
        plentymarketsID: 31
    },
    {
        Country: "Japan",
        ISOCode: "JP",
        plentymarketsID: 32
    },
    {
        Country: "Lithuania",
        ISOCode: "LT",
        plentymarketsID: 33
    },
    {
        Country: "Liechtenstein",
        ISOCode: "LI",
        plentymarketsID: 34
    },
    {
        Country: "Monaco",
        ISOCode: "MC",
        plentymarketsID: 35
    },
    {
        Country: "Mexico",
        ISOCode: "MX",
        plentymarketsID: 36
    },
    {
        Country: "Canary Islands",
        ISOCode: "IC",
        plentymarketsID: 37
    },
    {
        Country: "India",
        ISOCode: "IN",
        plentymarketsID: 38
    },
    {
        Country: "Brazil",
        ISOCode: "BR",
        plentymarketsID: 39
    },
    {
        Country: "Russia",
        ISOCode: "RU",
        plentymarketsID: 40
    },
    {
        Country: "Romania",
        ISOCode: "RO",
        plentymarketsID: 41
    },
    {
        Country: "Ceuta",
        ISOCode: "EA",
        plentymarketsID: 42
    },
    {
        Country: "Melilla",
        ISOCode: "EA",
        plentymarketsID: 43
    },
    {
        Country: "Bulgaria",
        ISOCode: "BG",
        plentymarketsID: 44
    },
    {
        Country: "Kosovo",
        ISOCode: "XZ",
        plentymarketsID: 45
    },
    {
        Country: "Kyrgyzstan",
        ISOCode: "KG",
        plentymarketsID: 46
    },
    {
        Country: "Kazakhstan",
        ISOCode: "KZ",
        plentymarketsID: 47
    },
    {
        Country: "Belarus",
        ISOCode: "BY",
        plentymarketsID: 48
    },
    {
        Country: "Uzbekistan",
        ISOCode: "UZ",
        plentymarketsID: 49
    },
    {
        Country: "Morocco",
        ISOCode: "MA",
        plentymarketsID: 50
    },
    {
        Country: "Armenia",
        ISOCode: "AM",
        plentymarketsID: 51
    },
    {
        Country: "Albania",
        ISOCode: "AL",
        plentymarketsID: 52
    },
    {
        Country: "Egypt",
        ISOCode: "EG",
        plentymarketsID: 53
    },
    {
        Country: "Croatia",
        ISOCode: "HR",
        plentymarketsID: 54
    },
    {
        Country: "Maldives",
        ISOCode: "MV",
        plentymarketsID: 55
    },
    {
        Country: "Malaysia",
        ISOCode: "MY",
        plentymarketsID: 56
    },
    {
        Country: "Hong Kong",
        ISOCode: "HK",
        plentymarketsID: 57
    },
    {
        Country: "Yemen",
        ISOCode: "YE",
        plentymarketsID: 58
    },
    {
        Country: "Israel",
        ISOCode: "IL",
        plentymarketsID: 59
    },
    {
        Country: "Taiwan",
        ISOCode: "TW",
        plentymarketsID: 60
    },
    {
        Country: "Guadeloupe",
        ISOCode: "GP",
        plentymarketsID: 61
    },
    {
        Country: "Thailand",
        ISOCode: "TH",
        plentymarketsID: 62
    },
    {
        Country: "Turkey",
        ISOCode: "TR",
        plentymarketsID: 63
    },
    {
        Country: "Greek Islands",
        ISOCode: "GR",
        plentymarketsID: 64
    },
    {
        Country: "Balearic Islands",
        ISOCode: "ES",
        plentymarketsID: 65
    },
    {
        Country: "New Zealand",
        ISOCode: "NZ",
        plentymarketsID: 66
    },
    {
        Country: "Afghanistan",
        ISOCode: "AF",
        plentymarketsID: 67
    },
    {
        Country: "Aland Islands",
        ISOCode: "AX",
        plentymarketsID: 68
    },
    {
        Country: "Algeria",
        ISOCode: "DZ",
        plentymarketsID: 69
    },
    {
        Country: "American Samoa",
        ISOCode: "AS",
        plentymarketsID: 70
    },
    {
        Country: "Andorra",
        ISOCode: "AD",
        plentymarketsID: 71
    },
    {
        Country: "Angola",
        ISOCode: "AO",
        plentymarketsID: 72
    },
    {
        Country: "Anguilla",
        ISOCode: "AI",
        plentymarketsID: 73
    },
    {
        Country: "Antarctica",
        ISOCode: "AQ",
        plentymarketsID: 74
    },
    {
        Country: "Antigua and Barbuda",
        ISOCode: "AG",
        plentymarketsID: 75
    },
    {
        Country: "Argentina",
        ISOCode: "AR",
        plentymarketsID: 76
    },
    {
        Country: "Aruba",
        ISOCode: "AW",
        plentymarketsID: 77
    },
    {
        Country: "Azerbaijan",
        ISOCode: "AZ",
        plentymarketsID: 78
    },
    {
        Country: "The Bahamas",
        ISOCode: "BS",
        plentymarketsID: 79
    },
    {
        Country: "Bahrain",
        ISOCode: "BH",
        plentymarketsID: 80
    },
    {
        Country: "Bangladesh",
        ISOCode: "BD",
        plentymarketsID: 81
    },
    {
        Country: "Barbados",
        ISOCode: "BB",
        plentymarketsID: 82
    },
    {
        Country: "Belize",
        ISOCode: "BZ",
        plentymarketsID: 83
    },
    {
        Country: "Benin",
        ISOCode: "BJ",
        plentymarketsID: 84
    },
    {
        Country: "Bermuda",
        ISOCode: "BM",
        plentymarketsID: 85
    },
    {
        Country: "Bhutan",
        ISOCode: "BT",
        plentymarketsID: 86
    },
    {
        Country: "Bolivia",
        ISOCode: "BO",
        plentymarketsID: 87
    },
    {
        Country: "Bosnia and Herzegovina",
        ISOCode: "BA",
        plentymarketsID: 88
    },
    {
        Country: "Botswana",
        ISOCode: "BW",
        plentymarketsID: 89
    },
    {
        Country: "Bouvet Island",
        ISOCode: "BV",
        plentymarketsID: 90
    },
    {
        Country: "British Indian Ocean Territory",
        ISOCode: "IO",
        plentymarketsID: 91
    },
    {
        Country: "Brunei Darussalam",
        ISOCode: "BN",
        plentymarketsID: 92
    },
    {
        Country: "Burkina Faso",
        ISOCode: "BF",
        plentymarketsID: 93
    },
    {
        Country: "Burundi",
        ISOCode: "BI",
        plentymarketsID: 94
    },
    {
        Country: "Cambodia",
        ISOCode: "KH",
        plentymarketsID: 95
    },
    {
        Country: "Cameroon",
        ISOCode: "CM",
        plentymarketsID: 96
    },
    {
        Country: "Cape Verde",
        ISOCode: "CV",
        plentymarketsID: 97
    },
    {
        Country: "Cayman Islands",
        ISOCode: "KY",
        plentymarketsID: 98
    },
    {
        Country: "Central African Republic",
        ISOCode: "CF",
        plentymarketsID: 99
    },
    {
        Country: "Chad",
        ISOCode: "TD",
        plentymarketsID: 100
    },
    {
        Country: "Chile",
        ISOCode: "CL",
        plentymarketsID: 101
    },
    {
        Country: "Christmas Island",
        ISOCode: "CX",
        plentymarketsID: 102
    },
    {
        Country: "Cocos Islands/Keeling Islands",
        ISOCode: "CC",
        plentymarketsID: 103
    },
    {
        Country: "Columbia",
        ISOCode: "CO",
        plentymarketsID: 104
    },
    {
        Country: "Comoros",
        ISOCode: "KM",
        plentymarketsID: 105
    },
    {
        Country: "Congo",
        ISOCode: "CG",
        plentymarketsID: 106
    },
    {
        Country: "Democratic Republic of the Congo",
        ISOCode: "CD",
        plentymarketsID: 107
    },
    {
        Country: "Cook Islands",
        ISOCode: "CK",
        plentymarketsID: 108
    },
    {
        Country: "Costa Rica",
        ISOCode: "CR",
        plentymarketsID: 109
    },
    {
        Country: "Ivory coast",
        ISOCode: "CI",
        plentymarketsID: 110
    },
    {
        Country: "Cuba",
        ISOCode: "CU",
        plentymarketsID: 112
    },
    {
        Country: "Djibouti",
        ISOCode: "DJ",
        plentymarketsID: 113
    },
    {
        Country: "Dominica",
        ISOCode: "DM",
        plentymarketsID: 114
    },
    {
        Country: "Dominican Republic",
        ISOCode: "DO",
        plentymarketsID: 115
    },
    {
        Country: "Ecuador",
        ISOCode: "EC",
        plentymarketsID: 116
    },
    {
        Country: "El Salvador",
        ISOCode: "SV",
        plentymarketsID: 117
    },
    {
        Country: "Equatorial Guinea",
        ISOCode: "GQ",
        plentymarketsID: 118
    },
    {
        Country: "Eritrea",
        ISOCode: "ER",
        plentymarketsID: 119
    },
    {
        Country: "Ethiopia",
        ISOCode: "ET",
        plentymarketsID: 120
    },
    {
        Country: "Falkland Islands",
        ISOCode: "FK",
        plentymarketsID: 121
    },
    {
        Country: "Faroe Islands",
        ISOCode: "FO",
        plentymarketsID: 122
    },
    {
        Country: "Fiji",
        ISOCode: "FJ",
        plentymarketsID: 123
    },
    {
        Country: "French Guiana",
        ISOCode: "GF",
        plentymarketsID: 124
    },
    {
        Country: "French Polynesia",
        ISOCode: "PF",
        plentymarketsID: 125
    },
    {
        Country: "French Southern and Antarctic Lands",
        ISOCode: "TF",
        plentymarketsID: 126
    },
    {
        Country: "Gabon",
        ISOCode: "GA",
        plentymarketsID: 127
    },
    {
        Country: "Gambia",
        ISOCode: "GM",
        plentymarketsID: 128
    },
    {
        Country: "Georgia",
        ISOCode: "GE",
        plentymarketsID: 129
    },
    {
        Country: "Ghana",
        ISOCode: "GH",
        plentymarketsID: 130
    },
    {
        Country: "Gibraltar",
        ISOCode: "GI",
        plentymarketsID: 131
    },
    {
        Country: "Greenland",
        ISOCode: "GL",
        plentymarketsID: 132
    },
    {
        Country: "Grenada",
        ISOCode: "GD",
        plentymarketsID: 133
    },
    {
        Country: "Guam",
        ISOCode: "GU",
        plentymarketsID: 134
    },
    {
        Country: "Guatemala",
        ISOCode: "GT",
        plentymarketsID: 135
    },
    {
        Country: "Guernsey",
        ISOCode: "GG",
        plentymarketsID: 136
    },
    {
        Country: "Guinea",
        ISOCode: "GN",
        plentymarketsID: 137
    },
    {
        Country: "Guinea-Bissau",
        ISOCode: "GW",
        plentymarketsID: 138
    },
    {
        Country: "Guyana",
        ISOCode: "GY",
        plentymarketsID: 139
    },
    {
        Country: "Haiti",
        ISOCode: "HT",
        plentymarketsID: 140
    },
    {
        Country: "Heard Island and McDonald Islands",
        ISOCode: "HM",
        plentymarketsID: 141
    },
    {
        Country: "Vatican City",
        ISOCode: "VA",
        plentymarketsID: 142
    },
    {
        Country: "Honduras",
        ISOCode: "HN",
        plentymarketsID: 143
    },
    {
        Country: "Iceland",
        ISOCode: "IS",
        plentymarketsID: 144
    },
    {
        Country: "Indonesia",
        ISOCode: "ID",
        plentymarketsID: 145
    },
    {
        Country: "Iran",
        ISOCode: "IR",
        plentymarketsID: 146
    },
    {
        Country: "Iraq",
        ISOCode: "IQ",
        plentymarketsID: 147
    },
    {
        Country: "Isle of Man",
        ISOCode: "IM",
        plentymarketsID: 148
    },
    {
        Country: "Jamaica",
        ISOCode: "JM",
        plentymarketsID: 149
    },
    {
        Country: "Jersey",
        ISOCode: "JE",
        plentymarketsID: 150
    },
    {
        Country: "Jordan",
        ISOCode: "JO",
        plentymarketsID: 151
    },
    {
        Country: "Kenya",
        ISOCode: "KE",
        plentymarketsID: 152
    },
    {
        Country: "Kiribati",
        ISOCode: "KI",
        plentymarketsID: 153
    },
    {
        Country: "Democratic People's Republic of Korea",
        ISOCode: "KP",
        plentymarketsID: 154
    },
    {
        Country: "Republic of Korea",
        ISOCode: "KR",
        plentymarketsID: 155
    },
    {
        Country: "Kuwait",
        ISOCode: "KW",
        plentymarketsID: 156
    },
    {
        Country: "Laos",
        ISOCode: "LA",
        plentymarketsID: 158
    },
    {
        Country: "Lebanon",
        ISOCode: "LB",
        plentymarketsID: 159
    },
    {
        Country: "Lesotho",
        ISOCode: "LS",
        plentymarketsID: 160
    },
    {
        Country: "Liberia",
        ISOCode: "LR",
        plentymarketsID: 161
    },
    {
        Country: "Libya",
        ISOCode: "LY",
        plentymarketsID: 162
    },
    {
        Country: "Macao",
        ISOCode: "MO",
        plentymarketsID: 163
    },
    {
        Country: "Macedonia",
        ISOCode: "MK",
        plentymarketsID: 164
    },
    {
        Country: "Madagascar",
        ISOCode: "MG",
        plentymarketsID: 165
    },
    {
        Country: "Malawi",
        ISOCode: "MW",
        plentymarketsID: 166
    },
    {
        Country: "Mali",
        ISOCode: "ML",
        plentymarketsID: 168
    },
    {
        Country: "Marshall Islands",
        ISOCode: "MH",
        plentymarketsID: 169
    },
    {
        Country: "Martinique",
        ISOCode: "MQ",
        plentymarketsID: 170
    },
    {
        Country: "Mauritania",
        ISOCode: "MR",
        plentymarketsID: 171
    },
    {
        Country: "Mauritius",
        ISOCode: "MU",
        plentymarketsID: 172
    },
    {
        Country: "Mayotte",
        ISOCode: "YT",
        plentymarketsID: 173
    },
    {
        Country: "Micronesia",
        ISOCode: "FM",
        plentymarketsID: 174
    },
    {
        Country: "Moldova",
        ISOCode: "MD",
        plentymarketsID: 175
    },
    {
        Country: "Mongolia",
        ISOCode: "MN",
        plentymarketsID: 176
    },
    {
        Country: "Montenegro",
        ISOCode: "ME",
        plentymarketsID: 177
    },
    {
        Country: "Montserrat",
        ISOCode: "MS",
        plentymarketsID: 178
    },
    {
        Country: "Mozambique",
        ISOCode: "MZ",
        plentymarketsID: 179
    },
    {
        Country: "Myanmar",
        ISOCode: "MM",
        plentymarketsID: 180
    },
    {
        Country: "Namibia",
        ISOCode: "NA",
        plentymarketsID: 181
    },
    {
        Country: "Nauru",
        ISOCode: "NR",
        plentymarketsID: 182
    },
    {
        Country: "Nepal",
        ISOCode: "NP",
        plentymarketsID: 183
    },
    {
        Country: "Netherlands Antilles",
        ISOCode: "AN",
        plentymarketsID: 184
    },
    {
        Country: "New Caledonia",
        ISOCode: "NC",
        plentymarketsID: 185
    },
    {
        Country: "Nicaragua",
        ISOCode: "NI",
        plentymarketsID: 186
    },
    {
        Country: "Niger",
        ISOCode: "NE",
        plentymarketsID: 187
    },
    {
        Country: "Nigeria",
        ISOCode: "NG",
        plentymarketsID: 188
    },
    {
        Country: "Niue",
        ISOCode: "NU",
        plentymarketsID: 189
    },
    {
        Country: "Norfolk Island",
        ISOCode: "NF",
        plentymarketsID: 190
    },
    {
        Country: "Northern Mariana Islands",
        ISOCode: "MP",
        plentymarketsID: 191
    },
    {
        Country: "Oman",
        ISOCode: "OM",
        plentymarketsID: 192
    },
    {
        Country: "Pakistan",
        ISOCode: "PK",
        plentymarketsID: 193
    },
    {
        Country: "Palau",
        ISOCode: "PW",
        plentymarketsID: 194
    },
    {
        Country: "Palestinian territories",
        ISOCode: "PS",
        plentymarketsID: 195
    },
    {
        Country: "Panama",
        ISOCode: "PA",
        plentymarketsID: 196
    },
    {
        Country: "Papua New Guinea",
        ISOCode: "PG",
        plentymarketsID: 197
    },
    {
        Country: "Paraguay",
        ISOCode: "PY",
        plentymarketsID: 198
    },
    {
        Country: "Peru",
        ISOCode: "PE",
        plentymarketsID: 199
    },
    {
        Country: "Philippines",
        ISOCode: "PH",
        plentymarketsID: 200
    },
    {
        Country: "Pitcairn Islands",
        ISOCode: "PN",
        plentymarketsID: 201
    },
    {
        Country: "Puerto Rico",
        ISOCode: "PR",
        plentymarketsID: 202
    },
    {
        Country: "Qatar",
        ISOCode: "QA",
        plentymarketsID: 203
    },
    {
        Country: "Reunion",
        ISOCode: "RE",
        plentymarketsID: 204
    },
    {
        Country: "Rwanda",
        ISOCode: "RW",
        plentymarketsID: 205
    },
    {
        Country: "Saint Helena",
        ISOCode: "SH",
        plentymarketsID: 206
    },
    {
        Country: "Saint Kitts and Nevis",
        ISOCode: "KN",
        plentymarketsID: 207
    },
    {
        Country: "Saint Lucia",
        ISOCode: "LC",
        plentymarketsID: 208
    },
    {
        Country: "Saint Pierre and Miquelon",
        ISOCode: "PM",
        plentymarketsID: 209
    },
    {
        Country: "Saint Vincent and the Grenadines",
        ISOCode: "VC",
        plentymarketsID: 210
    },
    {
        Country: "Samoa",
        ISOCode: "WS",
        plentymarketsID: 211
    },
    {
        Country: "San Marino",
        ISOCode: "SM",
        plentymarketsID: 212
    },
    {
        Country: "Sao Tome and Principe",
        ISOCode: "ST",
        plentymarketsID: 213
    },
    {
        Country: "Saudi Arabia",
        ISOCode: "SA",
        plentymarketsID: 214
    },
    {
        Country: "Senegal",
        ISOCode: "SN",
        plentymarketsID: 215
    },
    {
        Country: "Serbia",
        ISOCode: "RS",
        plentymarketsID: 216
    },
    {
        Country: "Seychelles",
        ISOCode: "SC",
        plentymarketsID: 217
    },
    {
        Country: "Sierra Leone",
        ISOCode: "SL",
        plentymarketsID: 218
    },
    {
        Country: "Solomon Islands",
        ISOCode: "SB",
        plentymarketsID: 219
    },
    {
        Country: "Somalia",
        ISOCode: "SO",
        plentymarketsID: 220
    },
    {
        Country: "South Africa",
        ISOCode: "ZA",
        plentymarketsID: 221
    },
    {
        Country: "South Georgia and the South Sandwich Islands",
        ISOCode: "GS",
        plentymarketsID: 222
    },
    {
        Country: "Sri Lanka",
        ISOCode: "LK",
        plentymarketsID: 223
    },
    {
        Country: "Sudan",
        ISOCode: "SD",
        plentymarketsID: 224
    },
    {
        Country: "Suriname",
        ISOCode: "SR",
        plentymarketsID: 225
    },
    {
        Country: "Spitsbergen and Jan Mayen",
        ISOCode: "SJ",
        plentymarketsID: 226
    },
    {
        Country: "Swaziland",
        ISOCode: "SZ",
        plentymarketsID: 227
    },
    {
        Country: "Syria",
        ISOCode: "SY",
        plentymarketsID: 228
    },
    {
        Country: "Tajikistan",
        ISOCode: "TJ",
        plentymarketsID: 229
    },
    {
        Country: "Tanzania",
        ISOCode: "TZ",
        plentymarketsID: 230
    },
    {
        Country: "Timor-Leste",
        ISOCode: "TL",
        plentymarketsID: 231
    },
    {
        Country: "Togo",
        ISOCode: "TG",
        plentymarketsID: 232
    },
    {
        Country: "Tokelau",
        ISOCode: "TK",
        plentymarketsID: 233
    },
    {
        Country: "Tonga",
        ISOCode: "TO",
        plentymarketsID: 234
    },
    {
        Country: "Trinidad and Tobago",
        ISOCode: "TT",
        plentymarketsID: 235
    },
    {
        Country: "Tunisia",
        ISOCode: "TN",
        plentymarketsID: 236
    },
    {
        Country: "Turkmenistan",
        ISOCode: "TM",
        plentymarketsID: 237
    },
    {
        Country: "Turks and Caicos Islands",
        ISOCode: "TC",
        plentymarketsID: 238
    },
    {
        Country: "Tuvalu",
        ISOCode: "TV",
        plentymarketsID: 239
    },
    {
        Country: "Uganda",
        ISOCode: "UG",
        plentymarketsID: 240
    },
    {
        Country: "Ukraine",
        ISOCode: "UA",
        plentymarketsID: 241
    },
    {
        Country: "United States Minor Outlying Islands",
        ISOCode: "UM",
        plentymarketsID: 242
    },
    {
        Country: "Uruguay",
        ISOCode: "UY",
        plentymarketsID: 243
    },
    {
        Country: "Vanuatu",
        ISOCode: "VU",
        plentymarketsID: 244
    },
    {
        Country: "Venezuela",
        ISOCode: "VE",
        plentymarketsID: 245
    },
    {
        Country: "Vietnam",
        ISOCode: "VN",
        plentymarketsID: 246
    },
    {
        Country: "British Virgin Islands",
        ISOCode: "VG",
        plentymarketsID: 247
    },
    {
        Country: "United States Virgin Islands",
        ISOCode: "VI",
        plentymarketsID: 248
    },
    {
        Country: "Wallis and Futuna",
        ISOCode: "WF",
        plentymarketsID: 249
    },
    {
        Country: "Western Sahara",
        ISOCode: "EH",
        plentymarketsID: 250
    },
    {
        Country: "Zambia",
        ISOCode: "ZM",
        plentymarketsID: 252
    },
    {
        Country: "Zimbabwe",
        ISOCode: "ZW",
        plentymarketsID: 253
    },
    {
        Country: "United Arab Emirates",
        ISOCode: "AE",
        plentymarketsID: 254
    },
    {
        Country: "Helgoland",
        ISOCode: "DE",
        plentymarketsID: 255
    },
    {
        Country: "Buesingen",
        ISOCode: "DE",
        plentymarketsID: 256
    },
    {
        Country: "Curaçao",
        ISOCode: "CUW",
        plentymarketsID: 258
    },
    {
        Country: "Sint Maarten",
        ISOCode: "SXM",
        plentymarketsID: 259
    },
    {
        Country: "BES Islands",
        ISOCode: "BES",
        plentymarketsID: 260
    },
    {
        Country: "Saint Barthélemy",
        ISOCode: "BL",
        plentymarketsID: 261
    },
    {
        Country: "Livigno",
        ISOCode: "IT",
        plentymarketsID: 262
    },
    {
        Country: "Campione d'Italia",
        ISOCode: "IT",
        plentymarketsID: 263
    },
    {
        Country: "Lake Lugano from Ponte Tresa to Porto Ceresio",
        ISOCode: "IT",
        plentymarketsID: 264
    },
    {
        Country: "Unknown",
        ISOCode: "--",
        plentymarketsID: 0
    }
];

export default { countries };
